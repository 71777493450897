import { createContext } from "react";
import { io } from "socket.io-client";
import { get } from "lodash";
const SocketContext = createContext();

function parseSubDomain(url = "") {
  try {
    if (!url) {
      url = process.env.REACT_APP_TEMP_BASE_URL || window.location.href;
    }

    const urlObj = new URL(url);

    const hostname = urlObj.hostname;

    const parts = hostname.split(".");

    if (parts.length > 2) {
      return parts.slice(0, -2).join(".");
    }

    return "";
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
}

export const socket = io(process.env.REACT_APP_SERVER_BASE_URL, {
  query: { subdomain: parseSubDomain() },
  reconnection: true,
  autoConnect: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
});

export default SocketContext;
