import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import router from "./router";

import { getCompanyDetail } from "./api/api";
import CompanyContext from "./context/CompanyContext";
import Backdrop from "@material-ui/core/Backdrop";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { enUS, deDE } from "@material-ui/core/locale";
import themee from "./theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import IsMutationProvider from "./providers/IsMutationProvider";
import ToastNotificationProvider from "./providers/ToastNotificationProvider";
import { Crisp } from "crisp-sdk-web";
import { get } from "lodash";
import "./App.css";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 111,
    color: "#fff",
  },
}));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

function App() {
  let lang = localStorage.getItem("default_lang") || "de";
  const theme = createMuiTheme(themee, lang == "de" ? deDE : enUS);
  const { t } = useTranslation();
  let pacer = useSelector((state) => state.pacer);
  const [company, setCompany] = React.useState({});
  const classes = useStyles();
  const companyDetail = () => {
    getCompanyDetail()
      .then((res) => {
        const { name, email } = res.data.company;
        Crisp.user.setEmail(email);
        Crisp.user.setNickname(name);
        Crisp.session.setData({
          ...res.data.company,
        });
        const company = res.data.company;
        if (get(company, "options")) {
          company.options = JSON.parse(get(company, "options"), "");
        }
        setCompany(company);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    companyDetail();
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <IsMutationProvider>
        <ThemeProvider theme={theme}>
          <ToastNotificationProvider>
            <CompanyContext.Provider value={company}>
              <div className="App">
                <Backdrop className={classes.backdrop} open={pacer.open}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <CircularProgress />
                    <p>{(pacer.msg = "Loading" ? t("loading") : pacer.msg)}</p>
                  </div>
                </Backdrop>
                <BrowserRouter>
                  <Switch>
                    <Route path="/" component={router} />
                  </Switch>
                </BrowserRouter>
              </div>
            </CompanyContext.Provider>
          </ToastNotificationProvider>
        </ThemeProvider>
      </IsMutationProvider>
    </QueryClientProvider>
  );
}

export default App;
