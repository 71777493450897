import React from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import ToastContext from "../context/ToastContext";
import { CheckLg } from "react-bootstrap-icons";

export const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  type: toast.TYPE.INFO,
  icon: <CheckLg />,
  closeButton: null,
  className: "toast-message",
  // pauseOnFocusLoss:false,
};

export default function ToastNotficationProvider({ children }) {
  return (
    <ToastContext.Provider value={toastOptions}>
      <>
        <ToastContainer />
        {children}
      </>
    </ToastContext.Provider>
  );
}
