import moment from "moment";
import { isObject, subtract, sum } from "lodash";
export function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}
export function DateStringFormater(date) {
  var d = new Date(date);
  var mm = d.getMonth() + 1;
  var dd = d.getDate();
  var yy = d.getFullYear();

  return (
    yy + "-" + (mm >= 10 ? mm : "0" + mm) + "-" + (dd >= 10 ? dd : "0" + dd)
  );
}

export function fiskalResponseFormatter(data) {
  let obj = {};
  console.log({ data });
  data.ftSignatures.map(function (d) {
    if (d.Caption == "<transaktions-nummer>") {
      obj.transaction_number = d.Data;
    }
    if (d.Caption == "<signatur-zaehler>") {
      obj.signature_no = d.Data;
    }

    if (d.Caption == "<start-zeit>") {
      obj.start_time = d.Data;
    }

    if (d.Caption == "<log-time>") {
      obj.end_time = d.Data;
    }
    if (d.Caption == "<kassen-seriennummer>") {
      obj.serial_number = d.Data;
    }
    if (d.Caption == "<log-time-format>") {
      obj.time_format = d.Data;
    }
    if (d.Caption == "<signatur>") {
      obj.signature = d.Data;
    }
    if (d.Caption == "<sig-alg>") {
      obj.alg_arthim = d.Data;
    }

    if (d.Caption == "<certification-id>") {
      obj.status = d.Data;
    }
  });

  return obj;
}

export function generateUID() {
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
}

export function germanNumberForamte(number = 0) {
  const num = Number(number);
  return num.toLocaleString("de-DE", { minimumFractionDigits: 2 }) + " €";
}

export const Format = {
  transformOrderToInvoice: (orderData) => {
    const invoiceData = {
      createdDate: orderData.created_at,
      invoiceNumber: orderData.order_no,
      fiscalData: isObject(orderData.fiskal_detail)
        ? orderData.fiskal_detail
        : orderData.fiskal_detail
        ? JSON.parse(orderData.fiskal_detail)
        : {},
      content: orderData.orderDetail.map((product) => {
        return {
          productId: product.product_id,
          productSizeId: product.product_size_id,
          name:
            product?.product_name ||
            product?.orderProduct?.name ||
            product?.offer_name,
          unitPrice: Number(product.unit_price),
          totalPrice: Number(product.total_price),
          size: product.productLabelSize?.size || { id: generateUID() },
          dineInTax: null,
          takeAwayTax: null,
          vatLabel: null,
          qty: product.qty,
          options: {
            remarks: product.note,
            ingredients: product.orderDetailIngredient.map((ingredient) => {
              return {
                id: ingredient.id,
                name: ingredient.name,
                qty: ingredient.qty,
                unitPrice: ingredient.price,
                totalPrice: ingredient.qty * ingredient.price,
              };
            }),
          },
        };
      }),

      subTotal: orderData.total_price,
      totalItems: orderData.orderDetail.length,
      totalTax: Number(orderData.vat_amount),
      discount: Number(orderData.discount),
      orderType: orderData.order_type === "TAKE_AWAY" ? "TAKE_AWAY" : "DINE_IN",
      table: orderData.orderTable,
      customer: orderData.orderCustomer,
      taxArray: orderData.orderVat.map((tax) => {
        return {
          vatLabel: tax.vat_label,
          takeAwayTax:
            orderData.order_type === "TAKE_AWAY" ? tax.vat_percentage : 0,
          dineInTax:
            orderData.order_type === "DINE_IN" ? tax.vat_percentage : 0,
          amount: tax.total_amount,
          taxAmount: tax.vat_amount,
        };
      }),
      total: subtract(
        sum([
          Number(orderData.total_price),
          Number(orderData.delivery_charges),
        ]),
        Number(orderData.discount)
      ),
      shippingCost: Number(orderData.delivery_charges),
    };
    return invoiceData;
  },
  removeEmptyPropertyFromObject(obj) {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
  },
  toTime: (time) => {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[4] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join("");
  },
  toGermanCurrency: (number, isSuffix = true) => {
    let num = Number(number);
    num = num.toLocaleString("de-DE", { minimumFractionDigits: 2 });
    if (isSuffix) return num + " €";
    return num;
  },
  toPercentage: (number = 0) => {
    if (number === "" || number === undefined || number == null) {
      return Format.toPercentage(0);
    }
    return number.toString() + " %";
  },
  toQueryString: (obj, prefix = "") => {
    function convertObjectToQueryString(obj, prefix) {
      const queryParams = [];

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const paramKey = prefix ? `${prefix}[${key}]` : key;

          if (typeof value === "object" && value !== null) {
            // Recursively convert nested objects
            queryParams.push(convertObjectToQueryString(value, paramKey));
          } else {
            // Encode the key-value pair and add it to the query params array
            const encodedKey = encodeURIComponent(paramKey);
            const encodedValue = encodeURIComponent(value);
            queryParams.push(`${encodedKey}=${encodedValue}`);
          }
        }
      }
      if (queryParams.length > 0) {
        return `${queryParams.join("&")}`;
      }

      return "";
    }

    const queryParams = convertObjectToQueryString(obj);
    if (!!queryParams) return `?${queryParams}`;
    return "";
  },
  toDate(date = "", format = "DD/MM/YYYY") {
    if (!date) date = new Date();
    return moment(date).format(format);
  },
  toDecimal: (number, places = 2) => {
    return Number(number.toFixed(places));
  },
};

// @Deprecated
export function toDate(date = "") {
  if (!date) date = new Date();
  return moment(date).format("DD/MM/YYYY");
}
