import React from "react";
import { Route, Redirect } from "react-router-dom";
import SocketProvider from "../providers/SocketProvider";

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("auth_user") ? (
        <SocketProvider>
          <Component {...props} />
        </SocketProvider>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default ProtectedRoute;
