import React, { useEffect } from "react";

import SocketContext, { socket } from "../context/Socket";

export default function SocketProvider({ children }) {
  useEffect(() => {
    socket.connect();
  }, []);
  return (
    <>
      <SocketContext.Provider value={{ socketInstance: socket }}>
        {children}
      </SocketContext.Provider>
    </>
  );
}
