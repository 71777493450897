
export const PACER_STATE_CHANGE = 'PACER_STATE_CHANGE';

export const ORDER = 'ORDER';

export const ORDER_ARRAY = 'ORDER_ARRAY';

export const HEADER_CHANGE = 'HEADER_CHANGE';


export const ORDER_RECEIVE='ORDER_RECEIVE';