import {ORDER_RECEIVE, HEADER_CHANGE, PACER_STATE_CHANGE, ORDER, ORDER_ARRAY } from '../constants/pacerConstants';

const intialState = {
    open: false,
    msg: 'Loading',
    orders: [],
    order_receive:true,
    header: false,
}

const cart = (state = intialState, action) => {
    switch (action.type) {
        case HEADER_CHANGE: return {
            ...state, header: action.payload.value
        };
        case ORDER_RECEIVE: return {
            ...state, order_receive: action.payload.value
        };
        case PACER_STATE_CHANGE: return {
            ...state,
            open: (action.payload.value ? action.payload.value : !state.open),
            msg: (action.payload.msg ? action.payload.msg : 'Loading')
        };
        case ORDER: return {
            ...state,
            orders: [action.payload, ...state.orders],
        };
        case ORDER_ARRAY: return {
            ...state,
            orders: action.payload,
        };
        default: return state;
    }
}

export default cart;