import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useIsMutating } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function IsMutationProvider({ children }) {
  const isMutation = useIsMutating();
  const { t } = useTranslation();

  useEffect(() => {
    if (isMutation) {
      notify();
    }
  }, [isMutation]);

  const notify = () =>
    toast.loading(t("loading"), {
      toastId: "mutation-key",
      autoClose: false,
      className: "toast-message",
      closeButton: null,
    });

  return <>{children}</>;
}
