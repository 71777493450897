import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "../screens/Auth/Login";
import ProgressBar from "./ProgressBar";
import ProtectedRoute from "./ProtectedRoute";
import AuthRoute from "./AuthRoute";
// Routes Imports
const Main = React.lazy(() => import("../screens/RestaurantPanel/Main"));
const AuthPage = React.lazy(() => import("../modules/auth/pages/AuthPage"));
const Invoice2 = React.lazy(() =>
  import("../modules/invoice2.0/routes")
);

const ForgotPasword = React.lazy(() =>
  import("../screens/Auth/ForgetPassword")
);
const ResetPassword = React.lazy(() => import("../screens/Auth/ResetPassword"));

function router() {
  return (
    <div className="App">
      <Suspense fallback={<ProgressBar />}>
        <BrowserRouter>
          <Switch>
            <AuthRoute path="/auth">
              <AuthPage />
            </AuthRoute>

            {/* @deprecation */}
            {/* <AuthRoute exact path="/login" component={Login} /> */}
            {/* @deprecation */}
            <AuthRoute path="/forgot-password" component={ForgotPasword} />
            {/* @deprecation */}
            <AuthRoute
              path="/password-reset/:id/:token"
              component={ResetPassword}
            />
            {/* @deprecation */}
            <ProtectedRoute
              path={`/invoice-cash-register`}
              component={Invoice2}
            />
            <ProtectedRoute path="/restaurant" component={Main} />
            {/* @deprecation */}
            <Route render={() => <Redirect to="/auth/login" />} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default router;
