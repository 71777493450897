import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
// TODO Have to replace this library
import "./lang/i18n";
import store from "./store";
import { Provider } from "react-redux";
import { Crisp } from "crisp-sdk-web";

Crisp.configure("37f4c47b-31e7-4363-b3c1-f2db850444a2");
ReactDOM.render(
  <Suspense fallback={null}>
    {/* TODO Have to Create a Global Provider */}
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  // TODO to Update this Selector
  document.getElementById("root__wrapper")
);
