export const ADD_TO_CART = "ADD_TO_CART";

export const REMOVE_ITEM = "REMOVE_ITEM";

export const ADD_SHIPPING_COST = 'ADD_SHIPPING_COST';

export const ADD_DISCOUNT = "ADD_DISCOUNT";

export const ADD_INGREDIENT = 'ADD_INGREDIENT'

export const CLEAR_CART = 'CLEAR_CART';

export const SET_TAX_TYPE = 'SET_TAX_TYPE';

export const REFRESH_CART = 'REFRESH_CART';

export const ADD_CUSTOMER = 'ADD_CUSTOMER';

export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER';

export const UPDATE_QTY = 'UPDATE_QTY';

export const ADD_TABLE = 'ADD_TABLE';

export const REMOVE_TABLE = 'REMOVE_TABLE';

export const ADD_NOTE = "ADD_NOTE";